<template>
  <div class="white-page">
    <router-link class="close-icon" to="/">
      <img
        src="@/assets/images/close-icon.svg"
        alt="close-icon"
        width="47"
        height="47">
    </router-link>
    <div class="paper-box">
      <div class="paper-menu">
        <div class="paper-menu-item">
          <div class="paper-menu-item_title">
            Founder &amp; CVO:
          </div>
          <div class="paper-menu-item_desc">
            Jonathan Touitou
          </div>
        </div>
        <div class="paper-menu-item">
          <div class="paper-menu-item_title">
            Co-Founder &amp; Lead Data Engineer:
          </div>
          <div class="paper-menu-item_desc">
            Shakir Waseeb
          </div>
        </div>
        <div class="paper-menu-item">
          <div class="paper-menu-item_title">
            Software Engineer:
          </div>
          <div class="paper-menu-item_desc">
            Rahimdad Faisal Safi
          </div>
        </div>
	<div class="paper-menu-item">
          <div class="paper-menu-item_title">
            Partner:
          </div>
          <div class="paper-menu-item_desc">
            <!-- Small logo -->
            <img class="small-logo" src="@/assets/images/berlin-logo.png">
          </div>
        </div>
      </div>
      <div class="paper-content">
        <div class="paper-content_title">
          WHITEPAPER
        </div>
        <div class="paper-content_desc">
          <p>
            Art is not merely a retinal stimulation that triggers [dis]pleasing
            emotions or thoughts. It is a vast game of references, a neural
            network connecting us across time and space. From the earliest marks
            of humanity found in caves to the sharpest contemporary works in
            museums, art has always been an evolving conversation—a web of
            ideas, symbols, and emotions that artists build upon and respond to. 
          </p>
          <p>
            Str-ing.org taps into this network, linking collectors, artists, and
            cultural practitioners at the heart of the art world. With forty
            million data entry points, we transform art collecting into a
            dynamic and intuitive data-driven experience. Just as every piece of
            art is a node in the vast history of art, collections are clusters,
            unique narratives revealed by the power of data analytics. 
          </p>
          <p>
            Our tools function like a neural network, processing vast amounts of
            real-time data to guide collectors through the intricate landscape
            of art. With Str-ing.org, collectors can analyze, showcase, and
            manage their collections in line with their vision of the world,
            keeping them attuned to ever-evolving cultural references and
            artistic trends. 
          </p>
          <p>
            The mission of Str-ing.org is to create an interconnected,
            collector-focused environment where art is not just acquired but
            understood within its broader context. By turning complex data into
            actionable insights, we empower collectors to actively participate
            in the ongoing game of reference, making informed decisions that
            resonate with the rich history and future of the art world and its
            market.
          </p>
	   <p>
    The mission of the Berlin based <a href="https://str-ing.org" style="color: blue; text-decoration: none;">Str-ing.org</a> 
    is to create an interconnected, collector-focused environment where art is not just 
    acquired but understood within its broader context.
  </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhitePaper",
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Akkurat';
  src: url('../assets/font/Akkurat-Light.ttf') format('truetype'),
       url('../assets/font/Akkurat.woff') format('woff');
  font-weight: 300; /* Light weight */
  font-style: normal;
}
.white-page {
  height: 100vh;
  padding: 100px 10vw;
  display: flex;
  flex-direction: column;
}

.paper-box {
  display: flex;
  overflow-y: auto; /* Allow vertical scroll if needed */
  .paper-menu {
    padding-top: 120px;
    .paper-menu-item {
      display: flex;
      margin-bottom: 50px;
      text-transform: uppercase;
      .paper-menu-item_title {
        width: 160px;
        color: #FF0000;
        font-size: 14px;
	/* New CSS */
	display: flex;
	align-items: center;
      }
      .paper-menu-item_desc {
        width: 190px;
        margin-left: 55px;
        font-size: 14px;
      }
    }
  }
  .paper-content {
    margin-left: 100px;
    .paper-content_title {
      font-size: 60px;
      margin-bottom: 40px;
    }
    .paper-content_desc {
      max-width: 790px;
      font-size: 16px; /* Text size */
      letter-spacing: -0.02em; /* Equivalent to -2% */
      line-height: 35px; /* Line height */
      overflow-y: auto; /* Trigger vertical scroll if content is too long */
      p {
        margin-bottom: 10px;
      }
    }
  }
}
.close-icon {
  display: block;
  position: absolute;
  top: 33px;
  right: 36px;
}

@media screen and (max-width: 1200px) {
  .white-page {
    padding: 10px 8px;
  }

  .paper-box {
    flex-direction: column;
    .paper-menu {
      padding-top: 60px;
    }
    .paper-content {
      margin-left: 0;
      .paper-content_title {
        font-size: 30px;
      }
    }
  }
  .close-icon {
    top: 15px;
    right: 15px;
    img {
      width: 30px;
      height: 30px;
    }
  } 
}

@media screen and (max-width: 450px) {
  /* Adjustments for smartphones */
  .paper-box {
    .paper-content {
      margin-left: 0;
      .paper-content_title {
        font-size: 20px;
      }
      .paper-content_desc {
        font-size: 16px;
        letter-spacing: -0.02em;
        line-height: 30px;
        max-width: 100%;
      }
     .paper-menu-item_title {
        width: 175px;
        color: #FF0000;
        font-size: 14px;
      }
     .paper-menu-item_desc {
        width: 190px;
        margin-left: 40px;
        font-size: 14px;
      }

    }
  }
}
  
/* Small logo */
.small-logo {
  width: 95px; /* Adjust size */
  height: auto;
}
</style>

